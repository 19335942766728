import React from 'react';
import BaseComponent from "../../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

import "./SiteSelectionComponent.css";

import tick from "../../../assets/europackaging/common_tick.svg";
import LoadingSpinner from "../../common/LoadingSpinner";
import ScreenTitleComponent from "../common/ScreenTitleComponent";

export default class SiteSelectionComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let selectedSite = localStorage.getItem("site");
        if (selectedSite != null) {
            selectedSite = JSON.parse(selectedSite);
        }

        this.initState({
            sitesNetworkInFlight: false,
            sitesNetworkError : null,
            sites: [],
            currentSite : selectedSite,
            siteFilter : ""
        });
    }

    componentDidMount() {
        this.getSitesFromNetwork();
    }

    siteWasSelected = (site) => {
        this.setState({
            currentSite : site
        });

        if (this.props.hasOwnProperty("siteChangeCallback")) {
            this.props.siteChangeCallback(site);
        }

        this.notifySiteChangeOverNetwork(site.id);
    }

    getSitesFromNetwork = () => {
        if (this.state.sitesNetworkInFlight) return;

        this.setState({
            sitesNetworkInFlight : true
        });

        Axios.post(ENDPOINTS.user.getUserSites, null)
            .then((r) => {
                let resp = API.parse(r);

                let sites = null;
                let error = null;

                if (resp.success) {
                    sites = resp.data.sites;
                } else {
                    error = API.formatError(resp);
                }

                this.setState({
                    sitesNetworkInFlight : false,
                    sites : sites,
                    sitesNetworkError : error
                });
            })
            .catch((e) => {
                console.log(e);

                this.setState({
                    sitesNetworkInFlight : false,
                    sitesNetworkError : "An unknown error has occurred. Please reload the page."
                });
            });
    }

    notifySiteChangeOverNetwork = (siteId) => {
        let formData = new FormData();
        formData.append("siteId", siteId);

        Axios.post(ENDPOINTS.user.setUserSite, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    console.log("Successfully notified server of Asset change");
                } else {
                    console.log("Error notifying server: " + resp.error.desc);
                }
            })
            .catch((e) => {
                console.log("Exception occurred while notifying server");
            });
    }

    render() {
        console.log("SELECTED SITE:");
        console.log(this.state.currentSite);

        let siteList = [];
        if (this.state.sitesNetworkInFlight) {
            siteList = (
                <LoadingSpinner />
            );
        } else {
            siteList = [];

            this.state.sites.forEach((site) => {
                let shouldAdd = true;
                if (this.state.siteFilter !== "") {
                    let filterTarget = this.state.siteFilter.toLowerCase();
                    shouldAdd = false;
                    if (site.name.toLowerCase().includes(filterTarget) ||
                        site.number.toLowerCase().includes(filterTarget) ||
                        site.addressLine1.toLowerCase().includes(filterTarget) ||
                        site.addressPostcode.toLowerCase().includes(filterTarget)) {
                        shouldAdd = true;
                    }
                }

                if (shouldAdd) {
                    let checkmark = (<span>&nbsp;</span>);
                    if (this.state.currentSite !== null) {
                        if (this.state.currentSite.id == site.id) {
                            checkmark = (<img src={tick} alt={"Y"} />);
                        }
                    }

                    siteList.push(
                        <div className={"site-item"} onClick={() => this.siteWasSelected(site)}>
                            <div className={"checkmark"}>{checkmark}</div>
                            <div className={"content-area"}>
                                <div className={"item-title"}>{site.name}</div>
                                <div className={"item-address"}>{site.addressLine1}, {site.addressPostcode}</div>
                            </div>
                        </div>
                    )
                }
            });

            if (siteList.length === 0) {
                siteList.push(
                    <div className={"row"}>
                        <div className={"col-12 text-center"}>
                            <em>No Assets to display</em>
                        </div>
                    </div>
                )
            }
        }

        return (
            <div className={"container site-selection-component"}>
                <ScreenTitleComponent title={"Change Site"} history={this.props.history} />

                <div className={"row"}>
                    <div className={"col-12 pt-3"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <label>Search Sites</label>
                                <input type={"text"} className={"form-control"} name={"siteFilter"} value={this.state.siteFilter} onChange={this.handleChange} placeholder={"Enter a asset name, number or postcode"} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"site-list"}>
                    { siteList }
                </div>
            </div>
        )
    }

}