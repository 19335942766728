import React from 'react';
import BaseComponent from "../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";
import {DataManager} from "../../data/DataManager";
import UIBlockerComponent from "../app/common/UIBlockerComponent";

export default class TemporaryUserLandingComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            temporaryLoginNetworkInFlight : false
        });
    }

    loginWasSuccessful = (token, user, site) => {
        this.props.loginCallback(token, user, site);
    }

    returnToLogin = () => {
        this.props.history.goBack();
    }

    submitUserOverNetwork = () => {
        if (this.state.temporaryLoginNetworkInFlight) return;

        this.setState({
            temporaryLoginNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("siteNumber", this.state.storeNumber);
        formData.append("userTypeId", API.userTypes.COVID19);

        Axios.post(ENDPOINTS.auth.createTemporarySession, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    DataManager.setTemporaryUserName(this.state.temporaryUserName);
                    this.loginWasSuccessful(resp.data.sessionToken, resp.data.user, resp.data.site);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    temporaryLoginNetworkInFlight : false
                });
            })
            .catch((e) => {
                this.showError("An unknown error has occurred. Please try again later. [A1100]");

                this.setState({
                    temporaryLoginNetworkInFlight : false
                });
            });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {
        return (
            <div className={"container temporary-user-landing-component"}>
                <div className={"row"}>
                    <div className={"hidden-xs col-md-2 col-lg-4"} />
                    <div className={"col-12 col-md-8 col-lg-4"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"screen-title"}>COVID-19 Check</div>
                                <p>Provide your name and asset number to start a COVID-19 check for that asset</p>
                            </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-12"}>
                                <label>Your Name</label>
                                <input type={"text"} className={"form-control"} name={"temporaryUserName"} value={this.state.temporaryUserName} onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-12"}>
                                <label>Asset Number</label>
                                <input type={"text"} className={"form-control"} name={"storeNumber"} value={this.state.storeNumber} onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className={"row form-block"}>
                            <div className={"col-12"}>
                                <button className={"btn btn-success btn-rounded btn-fullwidth"} onClick={this.submitUserOverNetwork}>Start the Cleaning Area Review Check</button>
                                <button className={"btn btn-secondary btn-rounded btn-fullwidth form-block"} onClick={this.returnToLogin}>Back to login page</button>
                            </div>
                        </div>
                    </div>
                </div>

                <UIBlockerComponent
                    shown={this.state.temporaryLoginNetworkInFlight} />
            </div>
        );
    }

}