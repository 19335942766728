import React from 'react';
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import BaseComponent from "../../BaseComponent";
import LoadingSpinner from "../../common/LoadingSpinner";

import "./CleaningSpecificationListComponent.css";

import indicatorWhite from "../../../assets/europackaging/arrow_right-white.svg";
import indicatorBlack from "../../../assets/europackaging/arrow_right-black.svg";
import ScreenTitleComponent from "../common/ScreenTitleComponent";
import {DataManager} from "../../../data/DataManager";

export default class CleaningSpecificationListComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let siteAreaId = this.getUriProperty("id", null);

        this.initState({
            specNetworkInFlight : false,
            expandedCleaningSpecificationIds : [],
            cleaningSpecs : null,
            siteAreaId : siteAreaId
        })
    }

    componentDidMount() {
        this.getCleaningSpecificationsFromNetwork();

        window._epShowSiteChangeButton(false);
    }

    componentWillUnmount() {
        window._epShowSiteChangeButton(true);
    }

    expandSection = (id) => {
        if (this.state.siteAreaId != null) return;

        let expandedIds = this.state.expandedCleaningSpecificationIds;

        if (!this.isAreaExpanded(id)) {
            expandedIds.push(id);
        } else {
            for (let i = 0; i < expandedIds.length; i++) {
                if (expandedIds[i] === id) {
                    expandedIds.splice(i, 1);
                }
            }
        }

        this.setState({
            expandedCleaningSpecificationIds: expandedIds
        });
    }

    isAreaExpanded = (id) => {
        for (let i = 0; i < this.state.expandedCleaningSpecificationIds.length; i++) {
            if (this.state.expandedCleaningSpecificationIds[i] === id) {
                return true;
            }
        }
        return false;
    }

    moveToDetailScreen = (siteAreaId, cleaningSpecId) => {
        this.props.history.push("/cleaning-specifications/view/" + siteAreaId + "/" + cleaningSpecId);
    }

    getCleaningSpecificationsFromNetwork = () => {
        if (this.state.specNetworkInFlight) return;

        this.setState({
            specNetworkInFlight : true
        });

        let formData = new FormData();
        if (this.state.siteAreaId !== null) {
            formData.append("siteAreaId", this.state.siteAreaId);
        }

        Axios.post(ENDPOINTS.cleaningSpec.getCleaningSpecifications, formData)
            .then((r) => {
                let cleaningSpecs = null;

                let resp = API.parse(r);
                if (resp.success) {
                    cleaningSpecs = resp.data.cleaningSpecifications;
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    specNetworkInFlight : false,
                    cleaningSpecs : cleaningSpecs
                });
            })
            .catch((e) => {
                console.log(e);
            })
    }

    showError = (message) => {
        this.props.showError("Error", message);
    }

    render() {
        let listItems = [];
        if (this.state.specNetworkInFlight) {
            listItems.push(
                <LoadingSpinner key={"loading"} />
            )
        } else {
            if (this.state.cleaningSpecs != null && this.state.cleaningSpecs.length > 0) {
                this.state.cleaningSpecs.forEach((spec) => {
                    let titleClassExtra = "";
                    let isExpanded = this.isAreaExpanded(spec.id) || this.state.siteAreaId != null;

                    if (isExpanded) {
                        titleClassExtra = " expanded";
                    }

                    listItems.push(
                        <div key={"t" + spec.id} className={"expandable-item expandable-title" + titleClassExtra} onClick={() => this.expandSection(spec.id)}>
                            <div className={"title-text"}>{ spec.name }</div>
                            <div className={"indicator"} style={{backgroundImage : "url(" + indicatorWhite + ")"}} />
                        </div>
                    )

                    if (isExpanded) {
                        spec.items.forEach((item) => {
                            listItems.push(
                                <div key={"s" + spec.id + "i" + item.id} className={"expandable-item"} onClick={() => this.moveToDetailScreen(spec.id, item.id)}>
                                    <div className={"title-text"}>{ item.title }</div>
                                    <div className={"indicator"} style={{backgroundImage : "url(" + indicatorBlack + ")"}} />
                                </div>
                            )
                        })
                    }
                });
            }
        }

        const appConfig = DataManager.getAppConfig();

        return (
            <div className={"container cleaning-specification-list-component"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <ScreenTitleComponent title={this.getOrDefault(appConfig, DataManager.appConfigKeys.Home.LABEL_CLEANING_SPEC, "Cleaning Specifications")} history={this.props.history} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"hidden-xs col-md-2 col-lg-3"} />
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <div className={"expandable-list"}>
                            {listItems}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}