import React from 'react';
import BaseComponent from "../../BaseComponent";
import {NavLink} from "react-router-dom";

import "./TopBarComponent.css";

import logoImageMS from "../../../assets/europackaging/ms_logo.png";
import logoImageGeneric from "../../../assets/europackaging/ep_logo.png";
import {Brand} from "../../../data/Brand";
import {API} from "../../../network/API";
import {DataManager} from "../../../data/DataManager";

export default class TopBarComponent extends BaseComponent {

    navigationOptions = [
        {
            to : "/",
            label : "Home",
            exact : true,
            visibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            to : "/sites",
            label : "Sites",
            exact : false,
            visibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            to : "/users",
            label : "Users",
            exact : false,
            visibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            to : "/audits",
            label : "Audits",
            exact : false,
            visibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            to : "/cleaning-specifications",
            label : "nav_cleaning_spec_label",
            defaultLabel : "Cleaning Specs",
            exact : false,
            visibility : [
                API.userTypes.ADMINISTRATOR
            ]
        },
        {
            to : "/contractor",
            label : "Contractor",
            exact : false,
            visibility : [
                API.userTypes.ADMINISTRATOR,
                API.userTypes.CONTRACTOR_MANAGER
            ]
        }
    ]

    constructor(props, context) {
        super(props, context);

        let user = DataManager.getUser();

        this.initState({
            user
        });
    }

    signOutWasClicked = () => {
        if (this.props.profileCallback !== undefined) {
            this.props.profileCallback();
        }
    }

    render() {
        let useLogo = logoImageGeneric;
        if (parseInt(process.env.REACT_APP_BRAND_ID) === Brand.MS) {
            useLogo = logoImageMS;
        }

        const appConfig = DataManager.getAppConfig();

        let navigationOptions = [];
        this.navigationOptions.forEach((navItem) => {
            let showOption = true;

            let label = navItem.label;
            if (navItem.defaultLabel !== undefined) {
                label = this.getOrDefault(
                    appConfig,
                    navItem.label,
                    navItem.defaultLabel
                );
            }

            if (navItem.hasOwnProperty("visibility")) {
                showOption = false;
                navItem.visibility.forEach((roleTypeId) => {
                    if (roleTypeId === parseInt(this.state.user.userTypeId)) {
                        showOption = true;
                    }
                })
            }

            if (showOption) {
                navigationOptions.push(
                    <NavLink className={"btn"} activeClassName={"active"} to={navItem.to}
                             exact={navItem.exact}>{label}</NavLink>
                );
            }
        })

        return (
            <div className={"top-bar-component"}>
                <div className={"logo"} style={{backgroundImage : "url(" + useLogo + ")"}} />
                <div className={"spacer"} />
                <div className={"actions"}>
                    {navigationOptions}

                    <NavLink className={"btn btn-info"} to={"/settings"}>Settings</NavLink>
                </div>
            </div>
        )
    }

}