import React from 'react';
import BaseComponent from "../../BaseComponent";
import ScreenTitleComponent from "../../app/common/ScreenTitleComponent";
import { v4 as uuidv4 } from "uuid";
import {CleaningSpecification} from "../../../data/CleaningSpecification";

import "./CleaningSpecificationEditorComponent.css";

import deleteIcon from "../../../assets/europackaging/trash_white.svg";
import mediaDocument from "../../../assets/europackaging/media_type_pdf.svg";
import mediaAudio from "../../../assets/europackaging/media_type_audio.svg";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {Toast} from "../common/ToastManagerComponent";
import UIBlockerComponent from "../../app/common/UIBlockerComponent";
import UploadProgressComponent from "../../app/common/UploadProgressComponent";
import {DataManager} from "../../../data/DataManager";

export default class CleaningSpecificationEditorComponent extends BaseComponent {

    inputFile = null;
    currentlyRequestingMediaTypeId = -1;

    deletedMediaIds = [];

    uploadFiles = [];

    isAdded = false;

    constructor(props, context) {
        super(props, context);

        let specificationId = this.getUriProperty("id", null);
        let isNewSpecification = false;
        if (specificationId != null) {
            if (specificationId === "new") {
                specificationId = null;
                isNewSpecification = true;
            }
        }

        this.initState({
            specificationNetworkInFlight : false,
            configNetworkInFlight : false,
            specificationSubmitNetworkInFlight : false,
            specification : null,
            media : [],
            siteAreas : [],
            selectedSiteAreas : [],
            specificationId,
            isNewSpecification,
            progressTitle : "",
            progressMessage : "",
            progressProgress : 0
        });

        this.inputFile = React.createRef();
    }

    componentDidMount() {
        this.isAdded = true;

        this.getEditorConfigFromNetwork();

        if (this.state.specificationId != null) {
            this.getCleaningSpecificationFromNetwork();
        }
    }

    componentWillUnmount() {
        this.isAdded = false;
    }

    createMediaObject = (mediaTypeId, mediaPath, title, file) => {
        return {
            localId : uuidv4(),
            cleaningSpecificationMediaTypeId : mediaTypeId,
            mediaPath : mediaPath,
            title : title,
            file : file
        };
    }

    requestMediaFile = (mediaTypeId) => {
        this.currentlyRequestingMediaTypeId = mediaTypeId;

        this.inputFile.current.click();
    }

    fileHadChanged = () => {
        if (this.inputFile.current.files.length > 0) {
            let file = this.inputFile.current.files[0];
            let media = this.state.media;
            media.push(this.createMediaObject(this.currentlyRequestingMediaTypeId, file.name, file.name, file));

            this.setState({
                media
            });
        }

        this.inputFile.current.value = null;
    }

    removeMedia = (mediaItem) => {
        let media = this.state.media;

        for (let i = 0; i < media.length; i++) {
            if ((mediaItem.id !== undefined && media[i].id === mediaItem.id) || (mediaItem.localId !== undefined && media[i].localId === mediaItem.localId)) {
                media.splice(i, 1);
            }
        }

        if (mediaItem.id !== undefined) {
            this.deletedMediaIds.push(mediaItem.id);
        }

        this.setState({
            media
        });
    }

    lookupYouTubeVideoWasPressed = () => {
        let videoId = this.parseYouTubeUrl(this.state.mediaVideoUrl);

        if (videoId != null) {
            let media = this.state.media;
            media.push(this.createMediaObject(CleaningSpecification.MEDIA_TYPES.VIDEO, videoId, ""));

            this.setState({
                mediaVideoUrl : "",
                media
            });
        } else {
            this.showError("The provided video URL is not supported. Please provide a different URL.");
        }
    }

    parseYouTubeUrl = (urlString) => {
        // https://youtu.be/UdfSrJvqY_E
        // https://www.youtube.com/watch?v=UdfSrJvqY_E
        // https://youtube.com/shorts/VXYvdWfkJMc
        if (urlString !== undefined && urlString != null) {
            let url = new URL(urlString);
            let pathParts = url.pathname.split("/");
            if (pathParts.length > 0) {
                if (url.hostname.includes("youtu.be")) {
                    return pathParts[pathParts.length - 1];
                } else if (pathParts.length > 2 && pathParts[1] === "shorts") {
                    return pathParts[2];
                } else {
                    let videoId = url.searchParams.get("v");
                    if (videoId !== undefined && videoId != null) {
                        return videoId;
                    }
                }
            }
        }
        return null;
    }

    checkSiteArea = (siteArea) => {
        let selectedSiteAreas = this.state.selectedSiteAreas;

        let needsAdding = true;
        for (let i = 0; i < selectedSiteAreas.length; i++) {
            if (selectedSiteAreas[i] == siteArea.id) {
                selectedSiteAreas.splice(i, 1);
                needsAdding = false;
            }
        }

        if (needsAdding) {
            selectedSiteAreas.push(siteArea.id);
        }

        this.setState({
            selectedSiteAreas
        });
    }

    siteAreaIsChecked = (siteAreaId) => {
        for (let i = 0; i < this.state.selectedSiteAreas.length; i++) {
            if (this.state.selectedSiteAreas[i] == siteAreaId) {
                return true;
            }
        }
        return false;
    }

    populateSpecificationIntoState = (specification) => {
        let specificationTitle = null;
        let selectedSiteAreas = [];
        let media = [];
        this.deletedMediaIds = [];

        if (specification != null) {
            specificationTitle = specification.title;

            if (specification.media !== undefined) {
                media = specification.media;
            }

            if (specification.siteAreas !== undefined) {
                specification.siteAreas.forEach((siteArea) => {
                    selectedSiteAreas.push(siteArea);
                })
            }
        }

        this.setState({
            specification,
            media,
            specificationTitle,
            selectedSiteAreas
        });
    }

    populateUploadFiles = () => {
        this.uploadFiles = [];

        this.state.media.forEach((media) => {
            if (media.localId !== undefined) {
                this.uploadFiles.push(media);
            }
        });
    }

    archiveWasPressed = () => {
        let title = "Archive Specification";
        let message = "Are you sure you want to archive this Specification? The Specification will no longer be available in the app.";
        let buttonLabel = "Archive";
        let buttonClass = "danger";
        if (this.state.specification.active == 0) {
            title = "Un-archive Specification";
            message = "Are you sure you want to un-archive this Specification? This Specification will be immediately available in the app.";
            buttonLabel = "Un-archive";
            buttonClass = "success";
        }

        this.props.showModal(title, message, [
            {
                label : buttonLabel,
                className : buttonClass,
                click : () => {
                    this.archiveCleaningSpecificationOverNetwork();
                    this.props.hideModal();
                }
            },
            {
                label : "Cancel",
                click : () => {
                    this.props.hideModal();
                }
            }
        ])
    }

    getEditorConfigFromNetwork = () => {
        if (this.state.configNetworkInFlight) return;

        this.setState({
            configNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.cleaningSpec.getSpecificationConfigurationTable)
            .then((r) => {
                let siteAreas = [];

                let resp = API.parse(r);
                if (resp.success) {
                    siteAreas = resp.data.siteAreas;
                } else {
                    Toast.show("Error", API.formatError(resp), Toast.TYPE_ERROR);
                }

                this.setState({
                    configNetworkInFlight : false,
                    siteAreas
                });
            })
            .catch((e) => {
                console.log(e);
                Toast.show("Error", "Unknown error when loading Configuration data", Toast.TYPE_ERROR);
                this.setState({
                    configNetworkInFlight : false
                });
            });
    }

    getCleaningSpecificationFromNetwork = (id) => {
        if (this.state.cleaningSpecNetworkInFlight) return;

        if (id === undefined) {
            id = this.state.specificationId;
        }

        this.setState({
            cleaningSpecNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("id", id);

        Axios.post(ENDPOINTS.cleaningSpec.getCleaningSpecificationAdmin, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.populateSpecificationIntoState(resp.data.specification);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    cleaningSpecNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error occurred. Please reload the page.");

                this.setState({
                    cleaningSpecNetworkInFlight : false
                });
            });
    }

    submitCleaningSpecificationOverNetwork = () => {
        if (this.state.specificationSubmitNetworkInFlight) return;

        if (this.state.specificationTitle === "") {
            this.showError("Please provide a title");
            return;
        }

        this.setState({
            specificationSubmitNetworkInFlight : true,
            progressTitle : "Submitting Specification",
            progressMessage : "Please wait...",
            progressProgress : 0
        });

        // Make sure upload files are prepared for upload now
        this.populateUploadFiles();

        let formData = new FormData();
        if (this.state.specification != null) {
            formData.append("id", this.state.specification.id);
        }
        formData.append("title", this.state.specificationTitle);
        formData.append("siteAreas", JSON.stringify(this.state.selectedSiteAreas));
        formData.append("deletedMediaIds", JSON.stringify(this.deletedMediaIds));

        Axios.post(ENDPOINTS.cleaningSpec.submitCleaningSpecification, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.populateSpecificationIntoState(resp.data.specification);

                    this.uploadSpecificationMedia(resp.data.specification.id,0);
                } else {
                    this.showError(API.formatError(resp));

                    this.setState({
                        specificationSubmitNetworkInFlight : false
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please reload the page. [D3500]")
                this.setState({
                    specificationSubmitNetworkInFlight : false
                });
            })
    }

    uploadSpecificationMedia = (cleaningSpecificationId, index) => {
        if (this.uploadFiles.length > 0 && index < this.uploadFiles.length) {
            this.setState({
                specificationSubmitNetworkInFlight : true,
                progressTitle : "Uploading Media",
                progressMessage : "Uploading file " + (index + 1) + " of " + this.uploadFiles.length
            });

            let uploadFile = this.uploadFiles[index];
            if (uploadFile != null) {
                let config = {
                    onUploadProgress: (progressEvent) => {
                        let progressTotal = progressEvent.lengthComputable ? progressEvent.total : 1;
                        // This progress will consider ALL images that need to be uploaded.
                        let progress = (100 * index) + Math.ceil((progressEvent.loaded / progressTotal) * 100) / this.uploadFiles.length;

                        this.setState({
                            progressProgress : progress
                        });
                    }
                }

                let formData = new FormData();
                formData.append("cleaningSpecificationId", cleaningSpecificationId);
                formData.append("cleaningSpecificationMediaTypeId", uploadFile.cleaningSpecificationMediaTypeId);

                if (uploadFile.cleaningSpecificationMediaTypeId !== CleaningSpecification.MEDIA_TYPES.VIDEO) {
                    formData.append("file", uploadFile.file);
                } else {
                    formData.append("videoId", uploadFile.mediaPath);
                }

                Axios.post(ENDPOINTS.cleaningSpec.uploadCleaningSpecificationMedia, formData, config)
                    .then((r) => {
                        let resp = API.parse(r);
                        if (resp.success) {
                            if (this.state.specification != null) {
                                this.state.specification.media = resp.data.media;

                                this.setState({
                                    media : resp.data.media
                                });
                            }
                        } else {
                            Toast.show("Error", API.formatError(resp), Toast.TYPE_ERROR);
                        }

                        this.uploadSpecificationMedia(cleaningSpecificationId, index + 1);
                    })
                    .catch((e) => {
                        console.log(e);
                        Toast.show("Error", "An unknown error has occurred uploading an image", Toast.TYPE_ERROR);
                    });
            } else {
                this.uploadSpecificationMedia(cleaningSpecificationId, index + 1);
            }
        } else {
            this.completeSpecificationSubmission();
        }
    }

    completeSpecificationSubmission = () => {
        // Await before dismissing progress to be more natural
        setTimeout(() => {
            if (this.isAdded) {
                this.setState({
                    specificationSubmitNetworkInFlight: false
                });
            }
        }, 400);
    }

    archiveCleaningSpecificationOverNetwork = () => {
        if (this.state.cleaningSpecNetworkInFlight) return;

        this.setState({
            cleaningSpecNetworkInFlight : true
        });

        let active = (this.state.specification.active == 1) ? "0" : "1";

        let formData = new FormData();
        formData.append("cleaningSpecificationId", this.state.specification.id);
        formData.append("active", active);

        Axios.post(ENDPOINTS.cleaningSpec.archiveCleaningSpecification, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.populateSpecificationIntoState(resp.data.specification);

                    let label = " archived";
                    if (active === "1") {
                        label = " un-archived";
                    }
                    Toast.show("Success", "Record successfully " + label, Toast.TYPE_SUCCESS);
                } else {
                    this.showError(API.formatError(resp));
                }

                this.setState({
                    cleaningSpecNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                this.showError("An unknown error has occurred. Please try again later. [D3600]");

                this.setState({
                    cleaningSpecNetworkInFlight : false
                });
            });
    }

    showError = (message) => {
        this.props.showModal("Error", message);
    }

    render() {
        let documentElements = [];
        let audioElements = [];
        let videoElements = [];

        this.state.media.forEach((media) => {
            if (media.cleaningSpecificationMediaTypeId == CleaningSpecification.MEDIA_TYPES.PDF) {
                documentElements.push(
                    <div className={"col-6 col-md-3 col-lg-2"}>
                        <div className={"embed-responsive embed-responsive-4by3 media-preview"}>
                            <div className={"file-content"}>
                                <div className={"spacer"} />
                                <div className={"preview-icon"} style={{backgroundImage : "url(" + mediaDocument + ")"}} />
                                <div className={"preview-title"}>{media.title}</div>
                                <div className={"spacer"} />
                            </div>
                            <div className={"remove"} style={{backgroundImage : "url(" + deleteIcon + ")"}} onClick={() => this.removeMedia(media)} />
                        </div>
                    </div>
                )
            } else if (media.cleaningSpecificationMediaTypeId == CleaningSpecification.MEDIA_TYPES.AUDIO) {
                audioElements.push(<div className={"col-6 col-md-3 col-lg-2"}>
                    <div className={"embed-responsive embed-responsive-4by3 media-preview"}>
                        <div className={"file-content"}>
                            <div className={"spacer"} />
                            <div className={"preview-icon"} style={{backgroundImage : "url(" + mediaAudio + ")"}} />
                            <div className={"preview-title"}>{media.title}</div>
                            <div className={"spacer"} />
                        </div>
                        <div className={"remove"} style={{backgroundImage : "url(" + deleteIcon + ")"}} onClick={() => this.removeMedia(media)} />
                    </div>
                </div>);
            } else if (media.cleaningSpecificationMediaTypeId == CleaningSpecification.MEDIA_TYPES.VIDEO) {
                videoElements.push(
                    <div className={"col-6 col-md-4 col-lg-3"}>
                        <div className={"embed-responsive embed-responsive-16by9 media-preview"}>
                            <iframe
                                title={"Video"}
                                src={"https://www.youtube.com/embed/" + media.mediaPath}
                                className={"video-player"}
                                frameBorder="0" />

                            <div className={"remove"} style={{backgroundImage : "url(" + deleteIcon + ")"}} onClick={() => this.removeMedia(media)} />
                        </div>
                    </div>
                )
            }
        });

        if (documentElements.length === 0) {
            documentElements = (
                <div className={"col-12 text-center empty-message"}>No Documents to display</div>
            )
        }

        if (audioElements.length === 0) {
            audioElements = (
                <div className={"col-12 text-center empty-message"}>No Audio to display</div>
            )
        }

        if (videoElements.length === 0) {
            videoElements = (
                <div className={"col-12 text-center empty-message"}>No Videos to display</div>
            )
        }

        let archiveButton = [];
        if (this.state.specificationId !== null) {
            if (this.state.specification != null) {
                if (this.state.specification.active == 1) {
                    archiveButton = (
                        <button className={"btn btn-danger"} onClick={this.archiveWasPressed}>Archive</button>);
                } else {
                    archiveButton = (
                        <button className={"btn btn-warning"} onClick={this.archiveWasPressed}>Un-archive</button>);
                }
            }
        }

        const appConfig = DataManager.getAppConfig();

        return (
            <div className={"container form-inputs cleaning-specification-editor-component"}>
                <div className={"row"}>
                    <div className={"col-8"}>
                        <ScreenTitleComponent
                            title={this.getOrDefault(appConfig, DataManager.appConfigKeys.Home.LABEL_CLEANING_SPEC, "Cleaning Specification")}
                            {...this.props} />
                    </div>
                    <div className={"col-4 screen-actions text-right"}>
                        {archiveButton}
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>Specification Title</label>
                        <input type={"text"} className={"form-control"} name={"specificationTitle"} value={this.state.specificationTitle} onChange={this.handleChange} />
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-6"}>
                        <h3>Documents</h3>
                    </div>

                    <div className={"col-6 screen-actions text-right"}>
                        <button className={"btn btn-primary"} onClick={() => this.requestMediaFile(CleaningSpecification.MEDIA_TYPES.PDF)}>Add Document</button>
                    </div>
                </div>

                <div className={"row"}>
                    {documentElements}
                </div>

                <div className={"row"}>
                    <div className={"col-6"}>
                        <h3>Audio</h3>
                    </div>

                    <div className={"col-6 screen-actions text-right"}>
                        <button className={"btn btn-primary"} onClick={() => this.requestMediaFile(CleaningSpecification.MEDIA_TYPES.AUDIO)}>Add Audio</button>
                    </div>
                </div>

                <div className={"row"}>
                    {audioElements}
                </div>

                <div className={"row"}>
                    <div className={"col-8"}>
                        <h3>Videos</h3>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"hidden-xs col-md-3"} />
                    <div className={"col-12 col-md-6"}>
                        <div className="input-group">
                            <input type="text" className="form-control" name={"mediaVideoUrl"} value={this.state.mediaVideoUrl} onChange={this.handleChange} placeholder="https://www.youtube.com/?v=abc123" />
                            <div className="input-group-append">
                                <button className="btn btn-primary" type={"button"} onClick={this.lookupYouTubeVideoWasPressed}>Add Video</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    {videoElements}
                </div>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <h3>Audit Areas</h3>
                    </div>
                </div>

                <div className={"row horizontal-check-list"}>
                    {
                        this.state.siteAreas.map((siteArea) => {
                            return (
                                <div className={"col-6 col-md-4 col-lg-3"}>
                                    <label>
                                        <input type={"checkbox"} checked={this.siteAreaIsChecked(siteArea.id)} onClick={() => this.checkSiteArea(siteArea)} />
                                        <span className={"text"}>{siteArea.name}</span>
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>

                <div className={"row submit-area"}>
                    <div className={"col-12"}>
                        <button className={"btn btn-success"} onClick={this.submitCleaningSpecificationOverNetwork}>Save Changes</button>
                    </div>
                </div>

                <div className={"file-hide"}>
                    <input type={"file"} ref={this.inputFile} onChange={this.fileHadChanged} />
                </div>

                <UIBlockerComponent
                    shown={this.state.specificationNetworkInFlight || this.state.configNetworkInFlight} />

                <UploadProgressComponent
                    open={this.state.specificationSubmitNetworkInFlight}
                    title={this.state.progressTitle}
                    message={this.state.progressMessage}
                    progress={this.state.progressProgress} />
            </div>
        )
    }

}